.gs-htg-body-bg {
    background-position: center;
    background-position-y: -30rem;
    background-repeat: no-repeat;
    margin-top: -1px;
}

.gs-htg-sign-up .btn {
    width: 7rem;
    height: 2.75rem;
}

.gs-htg-sign-up input {
    height: 2.75rem;
}

.htg-img {
    max-height: 20rem;
}

.nav-link.active {
    color: #3C3C3C !important;
    background-color: transparent !important;
    border-radius: 0;
    border-bottom: 3px solid #3C3C3C;
}

.nav-item a {
    padding: 20px 0 20px 0;
    color: #3C3C3C;
    border-radius: 0;
    border-bottom: 3px solid transparent;
}

.nav-item a:focus {
    outline: none;
}

.nav-item a:hover {
    border-radius: 0;
    border-bottom: 3px solid #3C3C3C;
}

.impact-tab {
    background-color: #77BCF7;
    padding: 20px;
    border-radius: 20px;
    color: #fff;
}

.gs-htg-hack-box {
    width: 100% !important;
    height: 27rem !important;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
    background-repeat: no-repeat !important;
    background-size: 100% !important;
}

.gs-htg-hack-box div {
    padding: 2rem;
    margin-top: auto;
    color: #fff;
}

.gs-htg-description img {
    margin-bottom: -20%;
}

.gs-htg-impact img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

#gs-htg-speaker-section img {
    max-width: 13rem;
}

#gs-htg-themes img {
    max-height: 15rem;
}

#gs-htg-speaker-bg {
    background-repeat: no-repeat;
    background-position-y: 30%;
    background-size: 25rem;
}

.gs-htg-impact-add-img {
    height: 38rem;
}

.gs-htg-impact-slide {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    color: #fff;
    padding: 2rem;
    width: auto;
    max-width: 27rem;
    height: 27rem;
    margin: 0 1rem 0 1rem;
    border-radius: 0.25rem;
    opacity: 0.7;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
}

.slick-center .gs-htg-impact-slide {
    opacity: 1;
}

.gs-htg-impact-slider {
    height: 30rem;
}

.htg-logos-section {
    height: 13rem;
}

#gs-htg-faq {
    background: linear-gradient(180deg, rgba(212, 238, 239, 0.3) 61.4%, rgba(212, 238, 239, 0) 100%);
}

#gs-htg-faq button {
    padding: 0;
    margin-bottom: 0.5rem;
    border: none;
    background-color: transparent;
    text-align: left;
    color: #4a4a4a;
}

#gs-htg-faq button:hover {
    color: #3d3d3d;
    text-decoration: underline;
}

#gs-htg-faq button:focus{
    outline: 0px !important;
}

.gs-htg-testimonial-block {
    padding: 5rem 0 5rem 0;
    height: 26rem;
}
.gs-htg-testimonial-block .row {
    height: 15rem;
}
.gs-htg-testimonial-slider {
    height: 27rem;
}

#htg .gs-landing .btn {
    border: 2px solid #fff;
    background-color: #3d3d3d;
    color: #fff;
}

#htg .gs-landing .btn:hover {
    border: 2px solid #3d3d3d;
    background-color: #fff;
    color: #3d3d3d;
}

@media (max-width: 1199px) {
    .gs-htg-impact-add-img {
        height: 35rem;
    }

    .htg-logos-section {
        height: 15rem;
    }

    .gs-htg-impact-slide {
        height: 30rem;
        margin: auto;
    }

    .gs-htg-impact-slider {
        height: 33rem;
    }

    #gs-htg-speaker-bg {
        background-size: 20rem;
    }
}

@media (max-width: 991px) {
    .gs-htg-body-bg {
        background-position-y: center;
        background-size: cover;
    }

    .htg-logos-section {
        height: 22rem;
    }
    .gs-htg-impact-slide {
        width: 25rem;
        height: 27rem;
        margin: auto;
    }
    .gs-htg-impact-slider {
        height: 27rem;
    }
    .gs-htg-impact-slide {
        opacity: 1;
    }

    #gs-htg-speaker-bg {
        background-position-y: 25%;
        background-size: 15rem;
    }
}

@media (max-width: 768px) {
    .htg-logos-section {
        height: 35rem;
    }
    .gs-htg-testimonial-block {
        height: 35rem;
    }
    .gs-htg-testimonial-block .row {
        height: 14rem;
    }
    .gs-htg-testimonial-slider {
        height: 36rem;
    }

    #gs-htg-speaker-bg {
        background-position-y: 25%;
        background-size: 15rem;
    }
}

@media (max-width: 576px) {
    .gs-htg-body-bg {
        background-position-y: 5rem;
    }
    .gs-htg-impact-slide {
        width: auto;
        margin-right: 1rem;
        margin-left: 1rem;
    }
    .gs-htg-testimonial-block {
        padding-top: 3rem;
        padding-bottom: 3rem;
        height: 41rem;
    }
    .gs-htg-testimonial-block .row {
        height: auto;
    }
    .gs-htg-testimonial-slider {
        height: 42rem;
    }

    #gs-htg-speaker-bg {
        background-position-y: 5%;
        background-size: 20rem;
    }
}
