.gs-schedule-tab-event-slider {
    height: 30rem;
}

.gs-schedule-tab-event-slide {
    width: 23rem;
    height: 28rem;
    margin:auto;
    border-radius: 0.25rem;
    opacity: 0.7;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
    background-color: #fff;
}

.slick-center .gs-schedule-tab-event-slide {
    opacity: 1;
}

.gs-schedule-tab-event-slide div {
    padding: 3rem;
    margin-top: auto;
    color: #3D3D3D;
}

.local-events-logos-section {
    height: 13.5rem;
}

@media (max-width: 1199px) {
    .gs-schedule-tab-event-slide {
        width: 17.5rem;
        height: 24rem;
    }
    .local-events-logos-section {
        height: 20rem;
    }
}

@media (max-width: 991px) {
    .gs-schedule-tab-event-slide {
        width: 18rem;
    }
    .local-events-logos-section {
        height: 22.5rem;
    }
}

@media (max-width: 768px) {
    .local-events-logos-section {
        width: 20rem;
        height: 45rem;
    }
}

@media (max-width: 576px) {
    .gs-schedule-tab-event-slide {
        width: 20rem;
    }
}
