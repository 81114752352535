.gs-team-tabs {
    border: none;
    display: flex;
    justify-content: center;
}

.gs-team-tabs .nav-item {
    border:none;
    color: #3C3C3C;
}

.gs-team-tabs .nav-item:hover {
    border:none;
    color: #3C3C3C;
}

.gs-team-tabs .nav-item:active {
    outline: none;
}

.gs-team-tabs .active {
    border-top: none;
    border-left: none;
    border-right: none;
    background: transparent;
    color: #3C3C3C;
    border-bottom: 2px solid #3C3C3C !important;
}
