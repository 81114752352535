.gs-example-tabs {
    border: none;
    display: flex;
    justify-content: center;
}

.gs-example-tabs .nav-item {
    border:none;
    color: #3C3C3C;
}

.gs-example-tabs .nav-item:hover {
    border:none;
    color: #3C3C3C;
}

.gs-example-tabs .nav-item:active {
    outline: none;
}

.gs-example-tabs .active {
    border-top: none;
    border-left: none;
    border-right: none;
    background: transparent;
    color: #3C3C3C;
    border-bottom: 2px solid #3C3C3C !important;
}

.gs-example-tab-event p {
    padding: 0;
    margin: 0;
}

.gs-example-tab-event img {
    height: 200px;
    width: 350px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 0 !important;
}

.gs-curriculum-example-slider {
    height: 28rem;
}

.gs-curriculum-example-slide {
    width: 22rem;
    height: 25rem;
    margin:auto;
    border-radius: 0.25rem;
    opacity: 0.7;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
    background-repeat: no-repeat !important;
    background-size: 100% !important;
}

.slick-center .gs-curriculum-example-slide {
    opacity: 1;
}

.gs-curriculum-example-slide div {
    padding: 2rem;
    margin-top: auto;
    color: #fff;
}

.curriculum-logos-section {
    height: 9rem;
}

.gs-curriculum-circle-img {
    background-color: #fff;
    height: 6rem;
    width: 6rem;
    padding: 1rem;
}

.gs-curriculum-circle-img-pill {
    padding: 20px;
    border-radius: 80px;
    color: #fff;
}

@media (max-width: 1199px) {
    .gs-curriculum-example-slide {
        width: 17.75rem;
    }
}

@media (max-width: 991px) {
    .gs-curriculum-example-slide {
        width: 20rem;
    }

    .curriculum-logos-section {
        height: 13.5rem;
    }
}

@media (max-width: 768px) {
    .curriculum-logos-section {
        height: 18rem;
    }
}

@media (max-width: 576px) {
    .gs-curriculum-example-slide {
        width: auto;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
