@media screen and (max-width: 600px){
    .podcast-header-graphics{
        height: 150px;
        margin: 1rem;
    }
}

.podcast-episode-container {
    padding: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    background: linear-gradient(313.09deg, rgba(223, 222, 255, 0.8) 5.34%, rgba(161, 196, 253, 0.8) 93.51%);
    box-shadow: 8px 12px 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
}

.podcast-showNotes-bold {
    color: #4542C2;
}