.image-size {
    width: 60px;
    height: 70px;
}

.image-position {
    position: absolute;
    left: 5%;
    transform: translateY(100.0%);
    width: 33%;
}

.demographic-icon {
    border-radius: 19px;
    height: 4.5rem;
    width: 4.5rem;
    padding: 1rem;
    margin-top: -0.5rem;
    border: 1px solid;
    border-color: #F0F0F0;
}

.bordered-col {
    width: 300px;
    height: 150px;
    border-radius: 45px;
    margin-right: 70px;
    border: 1.5px solid; /* I've added 'solid' for the border style */
    border-color: #F0F0F0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.text-with-underline {
    position: relative;
    display: inline-block;
}

.text-with-underline::before {
    content: "";
    position: absolute;
    bottom: 3px; /* Adjust this value to control the underline's position */
    left: 5px;
    width: 100%; /* Adjust this value to control the width of the underline */
    height: 16px; /* Adjust this value to control the underline thickness */
    background-color: rgba(235, 121, 121, 0.15); /* You can change the color here */
}


.highlight {
    position: relative;
    display: inline-block;
}

.highlight::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0px;
    width: 104%; /* Adjust this value to control the width of the underline */
    height: 16px; /* Adjust this value to control the underline thickness */
    background-color: rgba(241, 206, 124, 0.20);
}

/* Define a CSS class for the button */
.custom-button {
    display: inline-block;
    width: 141px;
    height: 53px;
    padding: 0 25px;
    border-radius: 12px;
    gap: 10px;
    background-color: #867EE4; /* Example background color (blue) */
    color: white; /* Text color */
    border: none;
    cursor: pointer;
}

.custom-button:hover {
    background-color: hsl(245, 58%, 71%); /* Example background color (darker blue) on hover */
}


.course-problem-header {
    color: #3D3737;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
}

.course-problem-paragraph {
    color: #6A6A6A;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
}

.bold-text {
    font-weight: 700;
    color: #424242;
}

.content-container {
    position: relative;
    left: 40px;
}

.landing-title {
    font: Satoshi;
    font-size: 56px;
    font-weight: 700;
    line-height: 1; /* Adjust the line height as needed */
    color: #363467;
    line-height: 119%;
}

.section-heading {
    font-size: 36px; /* Adjust the font size as needed */
    font-weight: bold; /* Bold */
    text-align: center;
    justify-content: center;
    color: #3D3737; /* Your desired color */
}

.bullet-heading {
    font-size: 24px; /* Adjust the font size as needed */
    font-weight: bold; /* Bold */
    color: #3D3737; /* Your desired color */
}

.bullet-text {
    margin-top: 15px;
    color: #6A6A6A;
    font-size: 18px;
}

.landing-description {
    color: #363467;
    line-height: 33.6px; /* Adjust the line height as needed */
    font-weight: 400;
    font-size: 24px;
}

.application-button {
    background-color: #ffffff;
    border: 2px solid #867EE4;
    border-radius: 16.94px;
    color: #867EE4;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: 74.82px;
    width: 485px;
}

.application-button:hover {
    background-color: #867EE4 !important;
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
}

.application-button-font {
    font-size: 22.59px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.03em;
    text-align: center;
    padding-top: 3%;
}


.gs-company-logo {
    margin: 6px;
    margin-top: 1px; /* Adjust the margin to control the vertical spacing */
}


.gs-example-tabs {
    border: none;
    display: flex;
    justify-content: center;
}

.gs-example-tabs .nav-item {
    border: none;
    color: #3C3C3C;
}

.gs-example-tabs .nav-item:hover {
    border: none;
    color: #3C3C3C;
}

.gs-example-tabs .nav-item:active {
    outline: none;
}

.gs-example-tabs .active {
    border-top: none;
    border-left: none;
    border-right: none;
    background: transparent;
    color: #3C3C3C;
    border-bottom: 2px solid #3C3C3C !important;
}

.gs-example-tab-event p {
    padding: 0;
    margin: 0;
}

.gs-example-tab-event img {
    height: 200px;
    width: 350px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 0 !important;
}

.gs-curriculum-example-slider {
    height: 28rem;
}

.gs-curriculum-example-slide {
    width: 22rem;
    height: 25rem;
    margin: auto;
    border-radius: 0.25rem;
    opacity: 0.7;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
    background-repeat: no-repeat !important;
    background-size: 100% !important;
}

.slick-center .gs-curriculum-example-slide {
    opacity: 1;
}

.gs-curriculum-example-slide div {
    padding: 2rem;
    margin-top: auto;
    color: #fff;
}

.content-inside-landing {
    display: flex; 
}

.cartoon-pos {
    align-self: center;
}

@media (max-width: 382px) {
    .gs-landing {
        width: 100%;
        height: 115vh;
    }
}

.curriculum-logos-section {
    height: 9rem;
}

.gs-sicp-circle-img {
    height: 102px;
    width: 105px;
    margin-top: -1rem;
}

.gs-sicp-circle-img-pill {
    padding: 20px;
    border-radius: 80px;
    color: #3D3737;
}

@media (max-width: 1199px) {
    .gs-curriculum-example-slide {
        width: 17.75rem;
    }


    .image-position {
        display: none;
    }
}

/* Default styling */
.second-card {
    margin-left: 0; /* Set default margin */
}

/* For large screens (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .second-card {
        margin-left: 21%;
    }
}

/* For extra large screens (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1420px) {
    .second-card {
        margin-left: 21%;
    }
}

/* For extra extra large screens (larger desktops, 1420px and up) */
@media (min-width: 1420px) {
    .second-card {
        margin-left: 23%;
    }
}

/* For very large screens (1600px and up) */
@media (min-width: 1600px) {
    .second-card {
        margin-left: 26%;
    }
}

/* For ultra wide screens (1920px and up) */
@media (min-width: 1920px) {
    .second-card {
        margin-left: 31%;
    }
}


@media (max-width: 991px) {
    .gs-curriculum-example-slide {
        width: 20rem;
    }

    .curriculum-logos-section {
        height: 13.5rem;
    }

    .image-position {
        display: none;
    }

    .gs-sicp-circle-img {
        margin-bottom: 2rem;
    }

    .cards-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0% !important;
    }

    .cards-top2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0% !important;
    }

    .second-card {
        margin-left: .2% !important;
    }

    .second-card2 {
        margin-left: .2% !important;
    }

    .timeline-card {
        margin-bottom: 3rem;
    }

    .client-col {
        width: 98%
    }

    .client-second-col {
        margin-left: 0% !important;
        margin-top: 2rem;
    }
}

@media (max-width: 767px) {
    .curriculum-logos-section {
        height: 26rem;
    }

    .title {
        font-size: 40px;
    }

    .description {
        font-size: 18px;
    }


    .image-position {
        display: none;
    }

    .highlight-sicp::before {
        width: 50%; /* Split into two lines on mobile screens */
    }

    .scope-list {
        padding-bottom: 3rem;
    }
}

@media (max-width: 576px) {
    .gs-curriculum-example-slide {
        width: auto;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .image-position {
        position: relative;
        display: flex;
        /* top: 150px; */
        left: 10%;
        transform: translateY(58%);
        width: 100vh;
    }

    .landing-title {
        font-size: 40px;
    }

    .landing-description {
        font-size: 18px;
    }

    /* Adjusted the margin of cards on smaller screens */
    .cards-top {
        margin-right: 1.5%;
        margin-left: 1.5%;
    }

    .cards-top2 {
        margin-right: 1.5%;
        margin-left: 1.5%;
    }
    .application-button {
        padding: 2px; 
        height: 50px; 
        width: 360px; 
    }
    .application-button-font {
        font-size: 20px;
        line-height: 20px; 
    }
}

@media (max-width: 450px) {
    .application-button {
        padding: 2px; 
        height: 50px; 
        width: 250px; 
    }
    .application-button-font {
        font-size: 16px;
        line-height: 28px; 
    }
}

.display-flex {
    display: flex;
}

.consulting-program-text {
    margin-top: 1rem;
}

.what-paragraph {
    color: #6A6A6A;
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0em;
}

.highlight-sicp {
    display: inline-block;
    position: relative;
}

.highlight-sicp::before {
    content: '';
    position: absolute;
    top: 14px; /* Adjust top to move the highlight down */
    left: 0;
    width: 104%;
    height: 12px;
    background: rgba(229, 229, 255, 1); /* Set the desired highlight color */
    z-index: -1; /* Ensure the pseudo-element is behind the text */
}

.bold-text {
    font-weight: 700;
}

.sicp-heading {
    display: flex;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    line-height: 110%;
}

.how-it-works-bg {
    background-color: rgba(241, 240, 255, 0.47);
    width: 98%;
    border-radius: 55px;
}

.scope-heading {
    font-size: 40px;
    font-weight: bold;
    color: #3D3737;
    text-align: center;
}

.scope-list {
    font-size: 18px;
    line-height: 140%;
    font-weight: normal;
    color: #6A6A6A;
}

.scope-coming-soon {
    display: flex;
    color: #6A6A6A;
    font-size: 24px;
    font-weight: bold;
    justify-content: center;
    padding-top: 2rem;
}

.timeline-bg {
    background-color: rgba(241, 240, 255, 0.47);
    width: 98%;
    border-radius: 55px;
}

.timeline-heading {
    font-size: 48px;
    font-weight: bold;
    color: #3D3737;
    text-align: center;
}

.timeline-card {
    background-color: white;
    border-radius: 25px;
    width: 100%; /* Set the width to 100% */
    height: 100%;
    max-width: 300px;
    padding: 0px 1.5% 0px 1.5%;
}


.second-card2 {
    margin-left: 36%;
}

.timeline-card-date {
    color: #6A6A6A;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 0.5rem;
}

.timeline-card-name {
    color: #3D3737;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5rem;
}

.timeline-card-para {
    color: #6A6A6A;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
}

.timeline-bar {
    width: 100%;
}

.cards-top {
    margin-left: 6%;
}

.cards-top2 {
    margin-left: 29%;
}

.client-bg {
    width: 98%;
    background-color: white;
}

.client-heading {
    font-size: 40px;
    font-weight: bold;
    color: #3D3737;
    text-align: center;
}

.client-col1 {
    background-color: #F8F8FF;
    border-radius: 31px;
    width: 100%;
    padding: 3% 2% 3% 2%;
}

.client-col2 {
    background-color: #F8F8FF;
    border-radius: 31px;
    width: 100%;
    padding: 3% 2% 0% 2%;
}

.client-second-col {
    margin-left: 3rem;
}

.client-text {
    color: #6A6A6A;
    font-size: 18px;
    font-weight: normal;
    padding: 3% 0% 0% 2%;
}

.client-image {
    width: 160px;
    height: 160px;
    border-radius: 14px;
}

.client-person-name {
    color: #3D3737;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0.5rem;
    padding-top: 5%;
}

.client-type-name {
    color: #3D3737;
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 0.1rem;
}

.client-org-name {
    color: #3D3737;
    font-weight: bold;
    font-size: 18px;
}

.join-text {
    color: #6A6A6A;
    font-size: 18px;
    font-weight: normal;
}

.apply-now {
    padding-top: 15%;
}

.font-bold {
    font-weight: bold;
}

.font-sicp {
    font-family: 'Satoshi', sans-serif;
}

/* mobile view vertical timeline styling */
.vertical-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #867EE4;
    height: 600px;
}

/* Hide vertical line for larger screens */
@media (min-width: 991px) {
    .vertical-line {
        display: none;
    }
}

/*adjusted sicp heading*/
@media (min-width: 320px) {
    .sicp-heading {
        display: flex;
        justify-content: center;
        font-size: 37px;
        font-weight: bold;
        line-height: 110%;
    }
}
