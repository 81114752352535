.card {
    overflow: hidden;
    border: none;
    border-radius: 0 !important;
}

.gs-panelists .card {
    background-color: transparent;
}

.gs-panelists-2021 .card img {
    min-width: 16rem;
    min-height: 16rem;
}

.gs-panelists-2020 .card img, .gs-panelists-2019 .card img {
    width: 13rem;
    height: 13rem;
    -o-object-fit: cover;
    object-fit: cover;
}
.gs-panelists-2020 .card img:hover, .gs-panelists-2021 .card img:hover {
    filter: brightness(75%)
}

.gs-panelist-title b {
    color: #F1BC00;
}

.gs-panelists-modal .modal-header {
    padding: 1rem 1rem 0 0;
    border-bottom: none;
}

.image-format img {
    width: 230px;
    height: 230px;
    -o-object-fit: cover;
    object-fit: cover;
}

.text-image-alignment {
    align-self: flex-end;
}

.panelists-modal {
    min-width: 60%;
}

.ai-panel-logos-section {
    height: 4.5rem;
}

.mt-n5 {
    margin-top: -3rem !important;
}

@media (max-width: 1199px) {
    .gs-panelists-2021 .card img {
        min-width: 13rem;
        min-height: 13rem;
    }
}

@media (max-width: 991px) {
    .ai-panel-logos-section {
        height: 9rem;
    }
}

@media (max-width: 768px) {
    .ai-panel-logos-section {
        height: 13.5rem;
    }
}
