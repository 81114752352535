.fade {
    -webkit-transition: opacity 0.5s ease-in;
    -moz-transition: opacity 0.5s ease-in;
    -o-transition: opacity 0.5s ease-in;
    opacity: 1;
}

.text-h3 h3 {
    font-size: 1.5rem !important;
}

.gs-icon {
    width: 63.707px;
    height: 78.878px;
}

.gs-icon-space {
    margin-right: -5pxl;
}

.gs-home {
    color: #3D3737;
    font-family: Poppins;
    font-size: 44.156px;
    font-style: normal;
    line-height: 100%; /* 44.156px */
}

.gs-home-landing {
    min-height: 45rem !important;
}

.gs-home-landing-text {
    /* adjusted from 60% to 90% */
    width: 90% !important;
}

.gs-home-body-bg {
    background-position: center;
    background-position-y: 5vw;
    background-size: cover;
    background-repeat: no-repeat;
}

.gs-home-bg {
    background-position: center;
    background-position-y: 5rem;
    background-size: cover;
    background-repeat: no-repeat;
}

.gs-home-objectives-mobile-arrow {
    height: auto !important;
}

.gs-home-sparking-text {
    color: #414042;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 99px */
}

.of-typed-wrapper {
    display: inline-block;
    white-space: nowrap;
}

@media (max-width: 320px) {
    .gs-home-sparking-text {
        color: #414042;
        justify-content: center;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 99px */
    }
}

.impact-image {
    border: 0 none;
    display: inline-block;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

.impact-header-font-size {
    font-size: 40px;
    margin-bottom: -15px;
}

.impact-font-size {
    font-size: 18px;
    margin-top: 5px;
}

.impact_image {
    margin-right: 10px;
}

.contact-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
}

.contact-header {
    font-size: 24px;
}

.contact-app {
    color: #867EE4;
    text-align: center;
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.48px;
}

.mb-15 {
    margin-bottom: 15rem !important;
}

.pt-10 {
    padding-top: 9rem !important;
}

.gs-home-impact-text, .gs-home-portfolio-section {
    margin-top: -8rem;
}

.highlight-impact {
    display: inline-block;
    position: relative;
}

.highlight-impact::before {
    content: '';
    position: absolute;
    top: 28px; /* Adjust top to move the highlight down */
    left: 5;
    width: 100%;
    height: 18px;
    background: rgb(251, 230, 230); /* Set the desired highlight color */
    z-index: -1; /* Ensure the pseudo-element is behind the text */
}

.gs-impact-bg-t, .gs-impact-bg-b {
    width: 100vw;
    -o-object-fit: cover;
    object-fit: cover;
}

.impact-card {
    border-radius: 40px;
    border: 1px solid rgb(238, 235, 235);
    display: flex;
    width: 340px;
    height: 150px;
    align-items: center;
    flex-shrink: 0;
    margin: 0 15px;
    margin: 15px 15px;
}

.gs-home-impact-block {
    padding-top: 25px;
    padding-bottom: 100px;
}

.pt-20 {
    position: relative;
    padding-top: 20rem !important;
}

@media (min-width: 415px) {
    .gs-home-objectives-mobile {
        height: 25rem;
    }

    .gs-home-objectives-mobile div {
        height: 100% !important;
    }

    .gs-home-objectives-mobile-arrow {
        height: 100% !important;
    }

    .pt-20 {
        position: relative;
        padding-top: 27rem !important;
    }
}

@media (min-width: 450px) {
    .gs-home-objectives-mobile {
        height: 27rem;
    }

    .gs-home-objectives-mobile div {
        height: 100% !important;
    }
}

@media (max-width: 991px) {
    .gs-objectives-block .row {
        flex-direction: column;
        align-items: center;
    }

    .gs-objectives-arrow {
        transform: rotateZ(90deg);
        margin-top: 30px;
        margin-bottom: 50px;
    }
}

.gs-objectives-block {
    background-position: center;
    background-position-y: 0rem;
    /* not sure why this is stretched out, need height to go down */
    background-size: 98% 98%, cover;
    background-repeat: no-repeat;
}

.gs-objectives-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gs-home-objectives p span {
    font-weight: bold;
    font-size: 1.15rem;
}

.gs-home-portfolio {
    padding: 0 0 12rem 0;
    align-items: center;
}

.gs-home-portfolio-image {
    position: absolute;
    width: 50%;
}

/* .gs-home-portfolio-image img {
    width: 100% !important;
} */

.gs-home-portfolio-text {
    position: absolute;
    margin-top: 10%;
    width: 40%;
    padding: 4rem 2rem 2rem 2rem;
    background-color: #fff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    color: #3D3D3D;
}

.gs-home-portfolio-right {
    right: 20%;
}

.gs-home-portfolio-left {
    left: 20%;
}

.home-logos-section {
    height: 18rem;
}

.gs-home-contact .btn {
    width: 8rem;
    border-radius: 10px;
    border: 2px solid #fff;
    background-color: #DC2249;
    color: #fff;
}

.gs-home-contact .btn:hover {
    background-color: #D61C3F;
    color: #fff;
}

.gs-home-contact .btn:active {
    background-color: #D01634 !important;
    border: none !important;
    color: #fff;
}

.gs-description {
    display: flex;
    align-items: center;
}

.gs-blurb {
    color: #6A6A6A;
    font-size: 24px;
}

.gs-contact-image {
    border-radius: 53px;
    border: 3px solid #FFF;
}

.gs-contact-button {
    display: flex;
    height: 53px;
    padding: 0px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.gs-portfolio {
    margin-bottom: -5px;
}

@media (max-width: 1030px) {
    .gs-portfolio-card2 {
        margin-right: 9%;
        margin-top: 5%;
    }

    .gs-portfolio-card4 {
        margin-right: 9%;
        margin-top: 5%;
    }
}

.gs-portfolio-card1 {
    border-radius: 54px;
    border: 3px solid #DF7575;
    display: flex;
    width: 476px;
    height: 282px;
    align-items: center;
    flex-shrink: 0;
}

.gs-portfolio-card2 {
    border-radius: 54px;
    border: 3px solid #D49800;
    display: flex;
    width: 474px;
    height: 282px;
    align-items: center;
    flex-shrink: 0;
}

.gs-portfolio-card3 {
    border-radius: 54px;
    border: 3px solid #8583CF;
    display: flex;
    width: 472px;
    height: 282px;
    align-items: center;
    flex-shrink: 0;
}

.gs-portfolio-card4 {
    border-radius: 54px;
    border: 3px solid #53B0C3;
    display: flex;
    width: 474px;
    height: 282px;
    align-items: center;
    flex-shrink: 0;
}

.gs-portfolio-header1 {
    color: #800014;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 35.2px */
    /* margin-bottom: -1 */
}

.gs-portfolio-header2 {
    color: #87640B;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 35.2px */
}

.gs-portfolio-header3 {
    color: #363467;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 35.2px */
}

.gs-portfolio-header4 {
    color: #0F4956;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 35.2px */
}

.gs-portfolio-description1 {
    color: #800014;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
}

.gs-portfolio-description2 {
    color: #87640B;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
}

.gs-portfolio-description3 {
    color: #363467;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
}

.gs-portfolio-description4 {
    color: #0F4956;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
}

.gs-learn-more-button1 {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    text-decoration: underline !important;
    text-decoration-color: #800014 !important;
    text-underline-offset: 4px !important;
}

.gs-learn-more-button1:hover {
    background-color: rgb(230, 230, 230) !important; /* Hover color */
}

.gs-learn-more-button2 {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    text-decoration: underline !important;
    text-decoration-color: #87640B !important;
    text-underline-offset: 4px !important;
}

.gs-learn-more-button2:hover {
    background-color: rgb(230, 230, 230) !important; /* Hover color */
}

.gs-learn-more-button3 {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    text-decoration: underline !important;
    text-decoration-color: #363467 !important;
    text-underline-offset: 4px !important;
}

.gs-learn-more-button3:hover {
    background-color: rgb(230, 230, 230) !important; /* Hover color */
}

.gs-learn-more-button4 {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    text-decoration: underline !important;
    text-decoration-color: #0F4956 !important;
    text-underline-offset: 4px !important;
}

.gs-learn-more-button4:hover {
    background-color: rgb(230, 230, 230) !important; /* Hover color */
}

.gs-portfolio-learn-more1 {
    color: #800014;
}

.gs-portfolio-learn-more2 {
    color: #87640B;
}

.gs-portfolio-learn-more3 {
    color: #363467;
}

.gs-portfolio-learn-more4 {
    color: #0F4956;
}

.globe {
    width: 950px; /* 50% of the viewport width */
    height: 950px;
    position: absolute;
    top: -350px !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.landing-cartoon1 {
    width: 334px;
    height: 357px;
}

.landing-cartoon2 {
    width: 334px;
    height: 357px;
}

.landing-cartoon3 {
    width: 362px;
    height: 383px;
}

.highlight-we-do {
    display: inline-block;
    position: relative;
}

.highlight-we-do::before {
    content: '';
    position: absolute;
    top: 29px; /* Adjust top to move the highlight down */
    left: 16px;
    width: 96%;
    height: 18px;
    background: rgb(161, 211, 222, 0.3); /* Set the desired highlight color */
    z-index: -1; /* Ensure the pseudo-element is behind the text */
}


@media (max-width: 1199px) {
    .gs-home-bg {
        background-position-y: 0;
    }

    .gs-home-portfolio-text {
        width: 50%;
        margin-top: 20%;
    }

    .home-logos-section {
        height: 22.5rem;
    }
}

@media (max-width: 991px) {
    .gs-home-portfolio {
        padding: 0 0 18rem 0;
    }

    .display-4 {
        font-size: 3rem !important;
    }

    .gs-home-impact-text, .gs-home-portfolio-section {
        margin-top: -5rem;
    }

    .gs-home-landing-text {
        width: 75% !important;
    }

    .home-logos-section {
        height: 27rem;
    }

    .gs-home-portfolio-image {
        position: absolute;
        width: 70%;
    }

    .gs-home-portfolio-text {
        margin-top: 30%;
        width: 60%;
    }

    .gs-home-portfolio-right {
        right: 0%;
    }

    .gs-home-portfolio-left {
        left: 0%;
    }
}

@media (min-width: 769px) and (max-width: 994px) {
    .globe {
        width: 820px;
        height: 820px;
        position: absolute;
        top: -270px !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    .gs-home-sparking-text {
        color: #414042;
        font-size: 62px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 99px */
    }
}

@media (max-width: 767px) {
    /*.gs-home-globe {*/
    /*    width: 50%;*/
    /*}*/
    .globe {
        width: 450px;
        height: 450px;
        position: relative;
        top: 300px !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
        margin-left: 30%;
    }

    .landing-cartoon1 {
        width: 40%;
        height: 35%;
        margin-top: 5rem;
        margin-right: 10rem;
    }

    .landing-cartoon2 {
        width: 40%;
        height: 40%;
        margin-top: 5rem;
        margin-right: 15rem;
    }

    .landing-cartoon3 {
        width: 45%;
        height: 45%;
        margin-right: 15rem;
    }

    .gs-impact-bg-t {
        padding-bottom: 7rem;
    }
}

@media (max-width: 768px) {
    .gs-home-impact-text, .gs-home-portfolio-section {
        margin-top: -2rem;
        padding-bottom: 3rem;
    }

    .gs-home-body-bg {
        background-position-y: -7rem;
        background-size: cover;
    }

    .gs-home-portfolio-image {
        position: absolute;
        width: 100%;
    }

    .gs-home-portfolio-right {
        right: 0%;
    }

    .gs-home-portfolio-left {
        left: 0%;
    }

    .gs-home-sparking-text {
        color: #414042;
        justify-content: center;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 99px */
    }
}

@media (max-width: 576px) {
    .display-4 {
        font-size: 2.5rem !important;
    }

    .gs-home-impact-text, .gs-home-portfolio-section {
        margin-top: 0;
    }

    .gs-home-portfolio-text {
        margin-top: 0;
        position: relative;
        width: 100%;
    }

    .gs-home-body-bg {
        background-position-y: -12rem;
    }

    .gs-home-landing-text {
        width: 1540px;
        height: 695px;
        flex-shrink: 0;
    }

    .globe {
        width: 450px;
        height: 450px;
        position: relative;
        top: 300px !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
        margin-left: 10%;
    }
}

@media (min-width: 1700px) {
    .globe {
        width: 990px;
        height: 990px;
        position: absolute;
        margin-left: 26% !important;
        top: -370px !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    .gs-home-sparking-text {
        color: #414042;
        font-size: 92px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 99px */
    }
}

